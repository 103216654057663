<template>
  <ion-buttons slot="start">
    <ion-button router-link="/"> <ion-icon :icon="backIcon" /></ion-button>
  </ion-buttons>
</template>

<script>
import { IonIcon, IonButton } from "@ionic/vue";

import { arrowBackOutline } from "ionicons/icons";

export default {
  name: "LogoutButton",
  components: {
    IonIcon,
    IonButton,
  },
  data() {
    return {
      backIcon: arrowBackOutline,
    };
  },
};
</script>

