<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <to-auth-button/>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <form @submit.prevent="handleRegister">
        <ion-item lines="full">
          <ion-label position="floating">Nome</ion-label>
          <ion-input
            @input="user.name = $event.target.value"
            type="text"
            required
          ></ion-input>
        </ion-item>

        <ion-item lines="full">
          <ion-label position="floating">Nome de usuario</ion-label>
          <ion-input
            @input="user.username = $event.target.value"
            type="text"
            required
          ></ion-input>
        </ion-item>

        <ion-item lines="full">
          <ion-label position="floating">Contrasinal</ion-label>
          <ion-input
            @input="user.password = $event.target.value"
            type="password"
            required
          ></ion-input>
        </ion-item>

        <ion-row>
          <ion-col>
            <ion-button type="submit" color="danger" expand="block"
              >Rexistrarse</ion-button
            >
          </ion-col>
        </ion-row>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import User from "../models/user";
import ToAuthButton from "@/components/ToAuthButton.vue"

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/vue";

export default {
  name: "Register",
  components: {
      ToAuthButton,
    IonHeader,
    IonToolbar,
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonButton,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/materiais");
    }
  },
  methods: {
    handleRegister() {
      this.message = "";
      this.submitted = true;

      this.$store.dispatch("auth/register", this.user).then(
        (data) => {
          console.log(data);
          this.message = data.message;
          this.successful = true;
        },
        (error) => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.successful = false;
        }
      );
    },
  },
  data() {
    return {
      user: new User("", "", ""),
      submitted: false,
      successful: false,
      message: "",
    };
  },
};
</script>